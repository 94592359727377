import React, { useState } from 'react';
import '.././index.css';
import { Slot } from './types';
import styled from 'styled-components';
import { backgroundSecondary, interactivePrimary, contentPrimary, redHighContrast } from '../Colors';
import SlotRow from './SlotRow';
import * as Dialog from '@radix-ui/react-dialog';
import { StyledDialogOverlay, StyledDialogContent, StyledDialogCloseButton, StyledDialogHeader, StyledDialogTitle } from '../styles';
import { IDriverReconciliationData, ISlotInfo } from '@driver-backup/lib';

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${backgroundSecondary};
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  align-self: stretch;

  @media (min-width: 1025px) {
    padding: 40px;
  }
`;

const StyledDriverName = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
`;

const StyledLogOutButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: ${interactivePrimary};
`;

const StyledHelpLink = styled.a`
  margin-left: 16px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const StyledSlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 801px) {
    padding: 0px 16px;
  }

  @media (min-width: 1025px) {
    padding: 0px 40px;
  }
`;

const StyledFooter = styled.div`
  width: 100%;
  padding: 20px 20px 160px 20px;
  box-sizing: border-box;

  @media (min-width: 801px) {
    padding: 16px 16px 160px 16px;
  }

  @media (min-width: 1025px) {
    padding: 40px 40px 160px 40px;
  }
`;

const StyledSendDataButton = styled.button`
  background-color: ${interactivePrimary};
  color: white;
  border: none;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  float: right;
`;

const StyledSendButton = styled.button`
  background-color: ${interactivePrimary};
  color: white;
  border: none;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  align-self: flex-end;
  border-radius: 8px;
  outline-color: ${contentPrimary};
  outline-offset: 4px;
  &:hover {
    opacity: 0.9;
  }
`;

const StyledDialogDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${contentPrimary};
`;

const SlotTable: React.FC<{ slots: Slot[]; driverName: string, dutyId: string, recoveryEmail: string, logOut: () => void }> = ({ slots, driverName, dutyId, recoveryEmail, logOut }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const formatEmailData = (): IDriverReconciliationData => {
    const slotInfo: ISlotInfo[] = slots.map((slot) => JSON.parse(localStorage.getItem(`slot_${slot.id}_data`) ?? '{}'));
    return {
      driverName,
      dutyId,
      slotInfo,
    }
  }

  const handleSendData = () => {
    // Call the API to send data
    fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: recoveryEmail,
        data: formatEmailData(),
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data sent successfully:', data);
        // You can add additional logic here, such as showing a success message
      })
      .catch(error => {
        console.error('Error sending data:', error);
        // You can add error handling logic here, such as showing an error message
      });
    setIsDialogOpen(false);
  };

  return (
    <StyledMain>
      <StyledHeader>
        <StyledHeaderContainer>
          <StyledDriverName>{driverName}</StyledDriverName>
          <StyledLogOutButton onClick={logOut}>Log Out</StyledLogOutButton>    
        </StyledHeaderContainer>
        <StyledHelpLink href="https://www.google.com" target="_blank" rel="noopener noreferrer">Help</StyledHelpLink>
      </StyledHeader>
      <StyledSlotsContainer>
        {slots.map((slot) => (
          <SlotRow key={slot.id} slot={slot} />
        ))}
      </StyledSlotsContainer>
      <StyledFooter>
        <StyledSendDataButton onClick={() => setIsDialogOpen(true)}>Finish Driving and Send Data</StyledSendDataButton>
      </StyledFooter>
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Portal>
          <StyledDialogOverlay />
          <StyledDialogContent>
            <StyledDialogHeader>
              <StyledDialogTitle>Finished Driving?</StyledDialogTitle>
              <StyledDialogCloseButton onClick={() => setIsDialogOpen(false)}>Close</StyledDialogCloseButton>
            </StyledDialogHeader>
            <StyledDialogDescription>
              This will send a copy of your actions and notes to dispatch.
            </StyledDialogDescription>
            <StyledSendButton onClick={handleSendData}>Yes, I've finished driving</StyledSendButton>
          </StyledDialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </StyledMain>
  );
};

export default SlotTable;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlotStatus = void 0;
var SlotStatus;
(function (SlotStatus) {
    SlotStatus["Scheduled"] = "scheduled";
    SlotStatus["Completed"] = "completed";
    SlotStatus["Cancelled"] = "cancelled";
    SlotStatus["InProgress"] = "inProgress";
})(SlotStatus || (exports.SlotStatus = SlotStatus = {}));

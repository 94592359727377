import { styled } from "styled-components";
import { scrim, interactivePrimary, contentPrimary } from "./Colors";
import * as Dialog from "@radix-ui/react-dialog";

export const StyledDialogOverlay = styled(Dialog.Overlay)`
  background-color: ${scrim};
  position: fixed;
  inset: 0;
`;

export const StyledDialogContent = styled(Dialog.Content)`
  gap: 16px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 8px 32px 0px rgba(27, 0, 82, 0.08), 0px 4px 24px 0px rgba(27, 0, 82, 0.08), 0px 4px 16px 0px rgba(27, 0, 82, 0.08), 0px 2px 8px 0px rgba(27, 0, 82, 0.08), 0px 2px 4px 0px rgba(27, 0, 82, 0.08), 0px 1px 2px 0px rgba(27, 0, 82, 0.08), 0px 1px 1px 0px rgba(27, 0, 82, 0.08), 0px 0px 0.5px 0.5px rgba(27, 0, 82, 0.08);  overflow: hidden;
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledDialogCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: ${interactivePrimary};
  cursor: pointer;
  outline-color: ${contentPrimary};
`;

export const StyledSaveButton = styled.button`
  background-color: ${interactivePrimary};
  color: white;
  border: none;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  align-self: flex-end;
  border-radius: 8px;
  outline-color: ${contentPrimary};
  outline-offset: 4px;
  &:hover {
    opacity: 0.9;
  }
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDialogTitle = styled(Dialog.Title)`
  font-size: 20px;
  font-weight: 700;
  color: ${contentPrimary};
  margin: 0;
`;

export const contentSecondary = '#545f87';
export const contentPrimary = '#1C2136';
export const contentInverted = '#FFFFFF';

export const backgroundPrimary = '#FFFFFF';
export const backgroundSecondary = '#F5F6FA';

export const borderSecondary = '#A3ABC7';
export const borderPrimary = '#E1E4EF';

export const interactivePrimary = '#0062FF';

export const greenLowContrast = '#B7F0DF';
export const greenHighContrast = '#004732';

export const redLowContrast = '#FFEDBE1';
export const redHighContrast = '#800015';

export const errorPrimary = '#E00025';

export const yellowLowContrast = '#FFE0AD';
export const yellowHighContrast = '#5C3300';

export const grayLowContrast = '#E1E4EF';
export const grayHighContrast = '#333B57';

export const blueLowContrast = '#D1E6FF';
export const blueHighContrast = '#003499';

export const scrim = 'rgba(28, 33, 54, 0.4)';
